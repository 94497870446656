import { parse, format, isValid } from 'date-fns';

export const parseDate = (dateString: string): string => {
  const formats = ['dd/MM/yyyy', 'yyyy-MM-dd'];

  // Intentar analizar la fecha en cualquiera de los formatos válidos
  const date = formats.reduce((parsedDate, currentFormat) => {
    if (parsedDate) return parsedDate; // Si ya es válida, mantenerla
    const tryParse = parse(dateString, currentFormat, new Date());
    return isValid(tryParse) ? tryParse : null;
  }, null as Date | null);

  // Si no se pudo analizar con los formatos, intentamos con el constructor de Date
  const finalDate = date || new Date(dateString);

  // Verificar si la fecha final es válida
  if (!isValid(finalDate)) {
    throw new Error(
      'Fecha con formato erróneo. Use uno de los siguientes formatos en su lugar: DD/MM/YYYY, YYYY-MM-DD, o un formato de fecha completo válido.'
    );
  }

  // Convertir la fecha al formato deseado
  return format(finalDate, 'yyyy-MM-dd');
};
