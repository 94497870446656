import { enUS, es } from 'date-fns/locale';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValid } from 'date-fns';
import { useTheme } from '@mui/material';

const localeMap: Record<string, any> = {
  es,
  en: enUS,
};

export const CustomDatePicker = ({
  name,
  onChange,
  value,
  inputProps,
  format,
  cancelLabel,
  okLabel,
}: {
  onChange: void | any;
  value: string | any;
  name?: string;
  inputProps?: string | any;
  format: string;
  cancelLabel?: string;
  okLabel?: string;
}): JSX.Element => {
  const theme = useTheme();
  const [currentDate, setCurrentDate] = useState(value !== '' ? value : null);
  const { i18n } = useTranslation();
  const language: string = i18n.language ? i18n.language : 'es';
  useEffect(() => {
    const date = new Date(value);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    if (typeof value === 'string' && !!value) {
      setCurrentDate(date);
    } else {
      setCurrentDate(value);
    }
  }, [value]);
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[language]}
    >
      <DatePicker
        slots={{
          openPickerIcon: () => (
            <>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33301 3.83331C5.05967 3.83331 4.83301 3.60665 4.83301 3.33331V1.33331C4.83301 1.05998 5.05967 0.833313 5.33301 0.833313C5.60634 0.833313 5.83301 1.05998 5.83301 1.33331V3.33331C5.83301 3.60665 5.60634 3.83331 5.33301 3.83331Z"
                  fill="#8F8999"
                />
                <path
                  d="M10.667 3.83331C10.3937 3.83331 10.167 3.60665 10.167 3.33331V1.33331C10.167 1.05998 10.3937 0.833313 10.667 0.833313C10.9403 0.833313 11.167 1.05998 11.167 1.33331V3.33331C11.167 3.60665 10.9403 3.83331 10.667 3.83331Z"
                  fill="#8F8999"
                />
                <path
                  d="M5.66667 9.66667C5.58 9.66667 5.49333 9.64668 5.41333 9.61335C5.32667 9.58001 5.26 9.53333 5.19333 9.47333C5.07333 9.34667 5 9.18 5 9C5 8.91334 5.02 8.82667 5.05333 8.74667C5.08667 8.66667 5.13333 8.59334 5.19333 8.52668C5.26 8.46668 5.32667 8.42 5.41333 8.38666C5.65333 8.28666 5.95333 8.34001 6.14 8.52668C6.26 8.65334 6.33333 8.82667 6.33333 9C6.33333 9.04 6.32667 9.08668 6.32 9.13335C6.31333 9.17335 6.3 9.21334 6.28 9.25334C6.26667 9.29334 6.24667 9.33334 6.22 9.37334C6.2 9.40667 6.16667 9.44 6.14 9.47333C6.01333 9.59333 5.84 9.66667 5.66667 9.66667Z"
                  fill="#8F8999"
                />
                <path
                  d="M7.99967 9.66664C7.91301 9.66664 7.82634 9.64665 7.74634 9.61332C7.65967 9.57999 7.59301 9.5333 7.52634 9.4733C7.40634 9.34664 7.33301 9.17998 7.33301 8.99998C7.33301 8.91331 7.35301 8.82664 7.38634 8.74664C7.41968 8.66664 7.46634 8.59332 7.52634 8.52665C7.59301 8.46665 7.65967 8.41997 7.74634 8.38663C7.98634 8.27997 8.28634 8.33998 8.47301 8.52665C8.59301 8.65332 8.66634 8.82664 8.66634 8.99998C8.66634 9.03998 8.65967 9.08665 8.65301 9.13332C8.64634 9.17332 8.63301 9.21331 8.61301 9.25331C8.59967 9.29331 8.57968 9.33331 8.55301 9.37331C8.53301 9.40664 8.49967 9.43997 8.47301 9.4733C8.34634 9.5933 8.17301 9.66664 7.99967 9.66664Z"
                  fill="#8F8999"
                />
                <path
                  d="M10.3337 9.66664C10.247 9.66664 10.1603 9.64665 10.0803 9.61332C9.99366 9.57999 9.92699 9.5333 9.86033 9.4733C9.83366 9.43997 9.80699 9.40664 9.78032 9.37331C9.75366 9.33331 9.73366 9.29331 9.72033 9.25331C9.70033 9.21331 9.68699 9.17332 9.68033 9.13332C9.67366 9.08665 9.66699 9.03998 9.66699 8.99998C9.66699 8.82664 9.74033 8.65332 9.86033 8.52665C9.92699 8.46665 9.99366 8.41997 10.0803 8.38663C10.327 8.27997 10.6203 8.33998 10.807 8.52665C10.927 8.65332 11.0003 8.82664 11.0003 8.99998C11.0003 9.03998 10.9937 9.08665 10.987 9.13332C10.9803 9.17332 10.967 9.21331 10.947 9.25331C10.9337 9.29331 10.9137 9.33331 10.887 9.37331C10.867 9.40664 10.8337 9.43997 10.807 9.4733C10.6803 9.5933 10.507 9.66664 10.3337 9.66664Z"
                  fill="#8F8999"
                />
                <path
                  d="M5.66667 12C5.58 12 5.49333 11.98 5.41333 11.9467C5.33333 11.9133 5.26 11.8666 5.19333 11.8066C5.07333 11.68 5 11.5066 5 11.3333C5 11.2466 5.02 11.16 5.05333 11.08C5.08667 10.9933 5.13333 10.92 5.19333 10.86C5.44 10.6133 5.89333 10.6133 6.14 10.86C6.26 10.9867 6.33333 11.16 6.33333 11.3333C6.33333 11.5066 6.26 11.68 6.14 11.8066C6.01333 11.9266 5.84 12 5.66667 12Z"
                  fill="#8F8999"
                />
                <path
                  d="M7.99967 12C7.82634 12 7.65301 11.9266 7.52634 11.8066C7.40634 11.68 7.33301 11.5066 7.33301 11.3333C7.33301 11.2466 7.35301 11.16 7.38634 11.08C7.41968 10.9933 7.46634 10.92 7.52634 10.86C7.77301 10.6133 8.22634 10.6133 8.47301 10.86C8.53301 10.92 8.57967 10.9933 8.61301 11.08C8.64634 11.16 8.66634 11.2466 8.66634 11.3333C8.66634 11.5066 8.59301 11.68 8.47301 11.8066C8.34634 11.9266 8.17301 12 7.99967 12Z"
                  fill="#8F8999"
                />
                <path
                  d="M10.3337 12C10.1603 12 9.98699 11.9267 9.86033 11.8067C9.80033 11.7467 9.75366 11.6733 9.72033 11.5867C9.68699 11.5067 9.66699 11.42 9.66699 11.3333C9.66699 11.2467 9.68699 11.16 9.72033 11.08C9.75366 10.9933 9.80033 10.92 9.86033 10.86C10.0137 10.7067 10.247 10.6333 10.4603 10.68C10.507 10.6867 10.547 10.7 10.587 10.72C10.627 10.7333 10.667 10.7533 10.707 10.78C10.7403 10.8 10.7737 10.8333 10.807 10.86C10.927 10.9867 11.0003 11.16 11.0003 11.3333C11.0003 11.5067 10.927 11.68 10.807 11.8067C10.6803 11.9267 10.507 12 10.3337 12Z"
                  fill="#8F8999"
                />
                <path
                  d="M13.6663 6.56H2.33301C2.05967 6.56 1.83301 6.33333 1.83301 6.06C1.83301 5.78666 2.05967 5.56 2.33301 5.56H13.6663C13.9397 5.56 14.1663 5.78666 14.1663 6.06C14.1663 6.33333 13.9397 6.56 13.6663 6.56Z"
                  fill="#8F8999"
                />
                <path
                  d="M10.6667 15.1666H5.33333C2.9 15.1666 1.5 13.7666 1.5 11.3333V5.66665C1.5 3.23331 2.9 1.83331 5.33333 1.83331H10.6667C13.1 1.83331 14.5 3.23331 14.5 5.66665V11.3333C14.5 13.7666 13.1 15.1666 10.6667 15.1666ZM5.33333 2.83331C3.42667 2.83331 2.5 3.75998 2.5 5.66665V11.3333C2.5 13.24 3.42667 14.1666 5.33333 14.1666H10.6667C12.5733 14.1666 13.5 13.24 13.5 11.3333V5.66665C13.5 3.75998 12.5733 2.83331 10.6667 2.83331H5.33333Z"
                  fill="#8F8999"
                />
              </svg>
            </>
          ),
        }}
        maxDate={new Date()}
        format={format}
        value={isValid(currentDate) ? currentDate : undefined}
        onChange={(newDate: any) => onChange(name, newDate, false)}
        sx={{
          width: '100%',
          '& div fieldset': {
            borderColor: `${theme.palette.primary.main} !important`,
          },
        }}
        data-test-id="date-picker-dialog"
        slotProps={{
          textField: {
            ...inputProps,
          },
        }}
        localeText={{
          cancelButtonLabel: cancelLabel,
          okButtonLabel: okLabel,
          toolbarTitle: '',
        }}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
