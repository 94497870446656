import { FunctionComponent } from 'react';
// import { Alert } from '@mui/lab';

import { useAlertStyles } from './Alert.styles';
import CloseIcon from '../../static/closeIcon.svg?react';
import { useTheme, Alert } from '@mui/material';
interface AlertProps {
  message: any | string;
  severity: any;
  onClose?(): void;
  className?: string;
}

export const CustomAlert: FunctionComponent<AlertProps> = ({
  message,
  severity = 'info',
  onClose,
  className,
}) => {
  const { classes } = useAlertStyles();
  const theme = useTheme();

  return (
    <Alert
      className={`${className} ${classes.alert}`}
      variant="filled"
      severity={severity}
      sx={{
        backgroundColor: () => {
          switch (severity) {
            case 'error':
              return '#FE739F';
            case 'warning':
              return '#E0CB5F';
            case 'success':
              return '#74E3B4';
            default:
              return '#92E1FF';
          }
        },
        color: () => {
          switch (severity) {
            case 'error':
              return '#B4093E';
            case 'warning':
              return '#605627';
            case 'success':
              return 'white';
            default:
              return '#4F7888';
          }
        },
        fontWeight: 'bold',
      }}
      action={
        <CloseIcon
          className={classes.iconClose}
          onClick={onClose}
          data-testid="close-icon"
          fill={theme.palette.primary.main}
        />
      }
    >
      {message}
    </Alert>
  );
};

export default CustomAlert;
